import { gql } from '@apollo/client';

export const MY_SPACES = gql`
  query getMySpaces {
    mySpaces {
      id
      message
      stream_link 
      groups {
        id
        name
        stream_link 
        users {
          id
          email
        }
        spaceMedia {
          uid
          url
          name
        }
      }
      opportunity {
        id
        title
        logo {
          path
        }
      }
      
    }
  }
`;
export const GET_MY_GROUPS_QUERY = gql`
  query getMyGroups {
    myGroups {
      id
      space_id
      users {
        id
        name
        avatar {
          path
        }
      }
     
    }
  }
`;
export const GET_SPACE = gql`
  query getSpace($id: String!) {
    space(id: $id) {
      id
      message
      stream_link
      groups {
        id
        name
        stream_link
        users{
          id
          name
          email
          avatar {
            path
          }
        }
        spaceMedia {
          uid
          url
          name
        }
      }
      spaceMedia {
        uid
        url
        name
      }
      opportunity{
        id
        title
        user {
          id
        }
        logo {
          path
        }
        cover {
          path
          name
        }
        media {
          path
          name
        }
        organisation {
          id
          name
          user {
            name
            email
          }
        }
      }
    }
  }
`;
export const GET_POSTS = gql`
  query spaceGroupPosts($groupId: String!) {
    spaceGroupPosts(groupId: $groupId) {
      id
      uid
      content
      media_url
      show_url
      user {
        id
        name
        email
        avatar {
          path
        }
      }
      group{
        id
        name
      }
      created_at
      comments_count
    }
  }
`;
export const GET_MY_SPACE_GROUPS = gql`
  query mySpaceGroups($groupId: String!) {
    mySpaceGroups(groupId: $groupId) {
      id
      name
    }
  }
`;

export const GET_POST_COMMENTS = gql`
  query getPostComments($postId: String) {
    postComments(postId: $postId) {
      content
      uid
      comment_type
      child_post_url
      user {
        name
        email
        avatar {
          path
        }
      }
    }
  }
`;
